html, #app {
  background: var(--ds-colour-surfaceSecondary);
  scroll-behavior: smooth;
}

@media screen and (max-width: 768px) {
  #app {
    background: var(--ds-colour-surfaceBackground);
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #0003;
  border-radius: 20px;
}

::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*# sourceMappingURL=base.e6572110.css.map */
