html,
#app {
  background: var(--ds-colour-surfaceSecondary);
  scroll-behavior: smooth;
}

@media screen and (max-width: 768px) {
#app {
    background: var(--ds-colour-surfaceBackground);
  }
}

*::-webkit-scrollbar{
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-thumb{
  background-color: rgba(0, 0, 0, .2);
  border-radius: 20px;
}

::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}